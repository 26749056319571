.no-scroll {
  overflow: hidden;
}

.app {
  margin: 0 auto;
  padding-bottom: 100px;

  &.logged-out {

  }

  &.logged-in {
    //background-color: #efefef;
  }
}

.header {
  background-color: #090830;
  height: 100px;
  border-bottom: 1px solid #e3e3e3;
  position: fixed;
  width: 100%;
  z-index: 1;

  .site-name {
    color: #fafafa;
    display: inline-block;
    padding-left: 25px;
    height: 57px;
  }

  .user-info {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 10px;

    .user-name,
    .user-id {
      display: none;
    }

    .user-image {
      width: 60px;
      height: 60px;
      display: inline-block;
      border-radius: 50%;
      float: right;
    }
  }
}

.user-profile {
  display: none;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: calc(100% - 160px);
  background-color: #ffffff;
  z-index: 3;
  overflow-y: scroll;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }

  .inner {

  }

  .user-details {
    padding: 25px 25px;

    .user-image {
      width: 100px;
      height: 100px;
      display: inline-block;
      border-radius: 50%;
      float: left;
    }

    .user-name {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      color: #090830;
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  .user-workouts {
    h2 {
      margin-left: 10px;
    }
  }

  .profile-actions {
    padding: 25px 25px;
    border-top: 1px solid #e3e3e3;

    .signout {
      border: 1px solid #ff4958;
      background-color: #FFFFFF;
      color: #ff4958;
      font-size: 18px;
      padding: 5px 10px;
      height: 30px;

      &:hover {
        background-color: #ff4958;
        color: #FFFFFF;
      }
    }
  }

}

.new-workout-container {
  display: none;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: calc(100% - 160px);
  background-color: #ffffff;
  z-index: 3;
  overflow-y: scroll;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }

  .inner {
    padding: 25px;

    .loader {
      position: absolute;
      z-index: 1;
      top: 45%;
      left: 45%;
    }
  }

  .form {

    &.loading {
      opacity: 0.4;
    }
  }

  button {
    border-radius: 5px;
    background-color: #090830;
    color: #fafafa;
    font-size: 14px;
    border: none;

    &:hover {
      background-color: #161481;
    }
  }

  input {
    width: 50%;
    min-width: 315px;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    padding-left: 10px;
  }

  .add-exercise-container {
    position: relative;
    top: 20px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 20px;

    .add-exercise-button {
      display: block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      font-size: 20px;
      line-height: 1.2;
    }

    .add-exercise-text {
      position: absolute;
      top: 10px;
      left: 40px;
      font-size: 12px;
    }
  }

  .exercise-field {
    margin-top: 25px;
    box-shadow: 1px 1px 4px 4px #e3e3e3;
    padding: 25px;
    max-width: 500px;
    min-width: 250px;
    position: relative;

    input {
      display: inline-block;
      min-width: 230px;
    }

    .add-set-button {
      display: block;
    }

    .remove-exercise-button {
      background-color: #ff4307;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 10px;
    }

    .exercise-sets {

      input {
        display: inline-block;
        width: 30%;
        min-width: 25%;
      }

      .set {
        display: inline-block;
        width: 16px;
        min-width: 16px;
        border: none;
        margin: 0 5px;
      }

      .weight {
        display: inline-block;
        width: 50px;
        margin: 0 5px;
      }

      .reps {
        display: inline-block;
        width: 50px;
        margin: 0 5px;
      }

      button {
        display: inline-block;
        background-color: #ff4307;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 10px;
      }
    }
  }

  .save-button {
    margin: 25px 5px 0 0;
  }

  .cancel-button {
    margin: 25px 5px 0 5px;
    background-color: #ffffff;
    color: #363636;
    border: 1px solid #363636;

    &:hover {
      background-color: #363636;
      color: #ffffff;
    }
  }
}

.body {
  max-width: 600px;
  padding: 0;
  position: relative;
  top: 100px;
  height: 100%;
  margin: 0 auto 60px;
  overflow-y: hidden;

  .login-link {
    text-align: center;
    margin-top: 60%;
    padding: 25px;

    a {
      color: #006e7c;
      text-decoration: none;
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      box-shadow: 0 0 4px 2px #e3e3e3;
      font-size: 14px;
      border-radius: 50%;
      padding: 15px;

      &:hover {
        background-color: #f0f8ff;
        color: #000000;
      }

      img {
        width: 60px;
        height: 60px;
        display: block;
      }
    }
  }

  h2 {
    margin-left: 15px;
  }

  .user-container {
    padding: 20px;
    background-color: #ffffff;
    border-bottom: 10px solid #efefef;
    border-top: 1px solid #efefef;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;

    .user-info {

      .following-user {
        position: relative;
        display: inline-block;
        padding: 0 5px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-block;
          border: 1px solid #090830;

          &:hover {
            border: 1px solid #006e7c;
            cursor: pointer;
          }
        }

        .recent-workout-tag {
          position: absolute;
          top: 0;
          left: 35px;
          background-color: #f32f3f;
          padding: 5px;
          border-radius: 50%;
          display: inline-block;
          border: 1px solid #f32f3f;
        }

      }
    }
  }


  .feed-container {

    .feed-content {

    }
  }

}

.workout-container {
  background-color: #ffffff;
  position: relative;
  border-bottom: 10px solid #efefef;
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  padding: 25px;

  &:last-child {
    border-bottom: none;
  }

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid #090830;

    &:hover {
      border: 1px solid #006e7c;
      cursor: pointer;
    }
  }

  h4 {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 75px;
    margin: 0;
  }

  .actions {
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;

    button {
      margin: 5px;
      background: #ffffff;
      border: 0;
      color: #a1a1a1;
    }
  }

  .datetime {
    display: inline-block;
    position: absolute;
    top: 50px;
    left: 75px;
    margin: 0;
    font-size: 10px;
  }

  .workout-details {
    padding: 20px 0;

    .workout-name-location {
      font-weight: 600;
    }
  }

  .exercise {
    padding: 5px 0;

    .workout-exercise-name {
      font-weight: 600;
      padding-left: 5px;
    }

    .exercise-set {
      padding-left: 15px;

      p {
        margin: 0;
      }
    }
  }
}

button {
  border: 1px solid #cccccc;
  background: #fafafa;
  height: 25px;
  border-radius: 5px;

  &:hover {
    background-color: #e8e8e8;
    cursor: pointer;
  }
}

.footer {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  border-top: 1px solid #e3e3e3;
  z-index: 4;

  .actions {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;

    button {
      margin: 10px 15px;
      bottom: 15px;
      background-color: #ffffff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 18px;
      color: #a1a1a1;
      border: 2px solid #d5d5d5;

      &:hover {
        background-color: #e1e1e1;
      }
    }
  }

  .home {

  }

  .add-workout {

  }

  .show-profile {

  }

}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loader:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #313131;
  border-color: #313131 transparent #313131 transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
